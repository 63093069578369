
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'gcfont';
    src: url('assets/fonts/Gucci-Font/Gucci-Font/Granjon.otf') format('opentype');

    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'gcfont';

}

li {
    list-style-type: disc;
    margin-left: 20px;
}

.max-w-desktop {
    max-width: 1200px;
}

@media screen and (max-width: 1216px) {
    .max-w-desktop {
        padding: 0 16px;
    }
}

.cart_sidebar_width {
    width: calc((100vw - 1200px) /2   + 260px);
}

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
   /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
  }
}